import React, { useState } from 'react';

import TextLanguage from '../../../../../data/TextLanguage.json';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


import { useUpdatePatientMutation } from '../hooks/useUpdatePatientMutation';

import {
    Box, Button,
    Typography,
    MenuItem, Select,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Tooltip
} from '@mui/material';

import { styled, useTheme } from '@mui/material/styles';

import { ActivityLog } from './ActivityLog';
import { useQueryClient } from '@tanstack/react-query';
import { PatientInfoList } from './PatientInfoList';


const SharpButton = styled(Button)({

    boxShadow: 'inset 0px 0px 12px 0px rgba(11, 11, 11,0.35)',
    outline: '1px solid rgba(11, 11, 11,0.25)',
    border: 'none',
    borderRadius: '0px !important',
    height: '38px',
    maxHeight: '100%',
    '&:hover': {
        boxShadow: 'inset 0px 0px 12px 0px rgba(11, 11, 11,0.05)',
        outline: 'none',
        border: 'none',
    },
    position: 'relative', // Bottom Border Shadow
    '&::before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: "100%",
        height: '1px',
        backgroundColor: 'rgba(22, 22, 22,0.75)',
        borderRadius: '0px',
        boxShadow: '0px 0px 10px 6px rgba(22, 22, 22,0.25)',
    },
});

//   fileUploader(uploadMutation, '.pdf,.doc,.docx,image/*', { uploadingFileType, selectedhospCaseID, clickedRowCaseNo: selectedRow?.RBKCaseNo })


const PatientInfoComponent = ({
    selectedRow, opMode, setformEditmodeDisplay, lang,
    selectedhospCaseID, setselectedhospCaseID, setselectedRow, setsnackbarStatus,
    onRowFileURLs, patientForm
}) => {


    const queryClient = useQueryClient();
    const hospitalUserListData = queryClient.getQueryData(['hospitalUserList']);

    const updatePatientMutation = useUpdatePatientMutation({ setformEditmodeDisplay, setselectedRow });

    const [newHospID, setnewHospID] = useState('');


    const [addHospOpen, setAddHospOpen] = useState(false);


    const handleAddHospClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setAddHospOpen(false);
        }
    };



    const handleAddHospSubmit = async (e, reason) => {

        if (reason !== 'backdropClick') {
            setAddHospOpen(false);
        }
        e.preventDefault();


        const appendedData = { updateType: 'addHospital', newHospID: newHospID };

        updatePatientMutation.mutate({ patientForm: selectedRow, appendedData: appendedData })

    };

    const theme = useTheme();

    const handleRemoveHospSubmit = () => {

        const appendedData = { updateType: 'removeHospital', userIDInsert: selectedhospCaseID };

        updatePatientMutation.mutate({ patientForm: selectedRow, appendedData: appendedData })

    };

    //const dense = useMediaQuery(theme.breakpoints.down('xl'));

    return <div className='PatientInfo-container'>

        <div className='infoSection'>
            <div className="patientInfoHeader">
                <div className='patientInfoCaseTitle'>
                    <Typography style={{ fontSize: "1rem", color: 'white', fontWeight: 'bold', textWrap: 'nowrap' }}> {selectedRow?.RBKCaseNo}  </Typography>
                </div>


                {opMode && <div className='hospitalAddRemButtonGroup'>

                    <Tooltip title={<Typography>{TextLanguage["EditPatient"][lang]}</Typography>} placement="top">
                        <span>
                            <SharpButton sx={{}} onClick={() => { setformEditmodeDisplay(false) }}>
                                <EditIcon />
                            </SharpButton>
                        </span>
                    </Tooltip>

                    <Tooltip title={<Typography>{TextLanguage["AddNewHospital"][lang]}</Typography>} placement="top">
                        <span>
                            <SharpButton sx={{}} onClick={() => { setAddHospOpen(true) }}>
                                <AddIcon sx={{ marginRight: '5px' }} />
                            </SharpButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={<Typography>{TextLanguage["RemoveSelectedHospital"][lang]}</Typography>} placement="top">
                        <span>

                            <SharpButton disabled={(selectedRow?.hospCases?.length > 1 && selectedhospCaseID) ? false : true}
                                sx={{

                                    background: 'darkred',
                                    '&:hover': { background: 'darkred' }
                                }}
                                onClick={handleRemoveHospSubmit}>
                                <RemoveIcon sx={{ marginRight: '5px' }} />

                            </SharpButton>
                        </span>
                    </Tooltip>

                    <Dialog sx={{ backdropFilter: 'blur(4px)' }} disableEscapeKeyDown open={addHospOpen}
                        onClose={handleAddHospClose}>
                        <DialogTitle sx={{ fontWeight: 'bolder', fontSize: '1.35rem' }}>{TextLanguage["SelectNewHospital"][lang]}</DialogTitle>
                        <DialogContent sx={{ background: 'transparent' }}>
                            <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap', background: 'transparent' }}>
                                <FormControl sx={{ m: 1, minWidth: 150 }}>
                                    <Select
                                        value={newHospID}
                                        onChange={(event) => { setnewHospID((event.target.value)); }}
                                    >
                                        {hospitalUserListData?.data.sort(
                                            (a, b) => -b.label.localeCompare(a.label)
                                        ).map((option) => (
                                            <MenuItem key={`Con-NewHospSelect-userID${option.value}`} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button sx={{ borderRadius: '0px' }} onClick={handleAddHospClose}>Cancel</Button>
                            <Button sx={{ borderRadius: '0px' }} onClick={handleAddHospSubmit}>Add</Button>
                        </DialogActions>
                    </Dialog>

                </div>}


            </div>

            {// Hospital Case Section
                opMode && <div className='hospitalCaseSection'>
                    {selectedRow?.hospCases?.map((hospCase, index) => {
                        const notSelected = (hospCase.userID === selectedhospCaseID) ? false : true;
                        return (
                            <div key={`hospitalCaseSubSection-${index}`} style={{
                                padding: '0px 2px',
                            }}>
                                <Button className={`hospitalCaseSelection ${notSelected ? 'hospitalCaseSelectionActive' : ''}`}
                                sx={{
                                    background: notSelected ? 'rgb(49,80,125)' : 'rgb(48, 120, 197)',
                                    color: notSelected ? 'lightgray' : 'white',
                                    '&:hover': {
                                        background: notSelected ? 'rgb(49,80,125)' : 'rgb(48, 120, 197)',
                                        filter: notSelected ? 'brightness(1.25) contrast(1.1)' : 'brightness(1.15)',

                                    },
                                    width: '100%', padding: '5px', borderRadius: '0px', color: notSelected ? 'lightgray' : 'white'
                                }} onClick={() => { setselectedhospCaseID(hospCase.userID); }}
                                >
                                    {`${hospitalUserListData?.data.find(hosp => hospCase.userID === hosp.value)?.label}`}
                                </Button>
                            </div>
                        );

                    })}
                </div>}

            <PatientInfoList
                lang={lang}
                selectedRow={selectedRow}
                selectedhospCaseID={selectedhospCaseID}
                onRowFileURLs={onRowFileURLs}
                setselectedRow={setselectedRow}
                opMode={opMode}
                setsnackbarStatus={setsnackbarStatus}
            />



            {opMode && <ActivityLog RBKCaseNo={selectedRow?.RBKCaseNo} mainActivityLog={false} lang={lang} />}

        </div>
    </div>;




}


export default PatientInfoComponent;










