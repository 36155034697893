
import React, { useRef, useState, useEffect } from "react";
import TextLanguage from "../../data/TextLanguage.json";

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';


import {
    CircularProgress,
    Backdrop,
    useMediaQuery
} from '@mui/material';


import { useTheme } from '@mui/material/styles';

import { progressHeader } from "./components/progressHeader";
import { useUploadShortcutMutation } from "../../hooks/useUploadShortcutMutation";
import { useQueryClient } from "@tanstack/react-query";
import { usePatientsQuery } from "../../hooks/usePatientsQuery";
import { CompletionBar } from "./components/CompletionBar";
import { formatCaseNo } from "./ColumnFormats/formatCaseNo";
import { formatDateAdm } from "./ColumnFormats/formatDateAdm";
import { formatDateUpload } from "./ColumnFormats/formatDateUpload";
import { formatInsuranceCompany } from "./ColumnFormats/formatInsuranceCompany";
import { formatLDMCaseNo } from "./ColumnFormats/formatLDMCaseNo";
import { formatGOPPrice } from "./ColumnFormats/formatGOPPrice";
import { formatPatientName } from "./ColumnFormats/formatPatientName";
import { formatHospitalUserID } from "./ColumnFormats/formatHospitalUserID";

import { rowClass } from "./ColumnFormats/rowClass";
import { DataTableHeader } from "./components/DataTableHeader";
import { DataTableFooter } from "./components/DataTableFooter";
import { useSearchParams } from "react-router-dom";

export const FileShareTable = ({
    handleSort, contextMenuRef, rightselectedRow, setrightselectedRow,
    selectedRow, opMode, lang, setformOpen, formOpen, setFormCreateMode, setselectedRow,
    setmodalPosition, setsnackbarStatus,
    setSelectedTab,
    loadingPatientsList, setloadingPatientsList,
    confirmedUserID,
    viewportWidth,
    setlang,

    searchFilter, setSearchFilter
}) => {


    const queryClient = useQueryClient();

    const InsuranceCompanyListData = queryClient.getQueryData(['insCoList']);
    const hospitalUserListData = queryClient.getQueryData(['hospitalUserList']);

    const datatableRef = useRef(null);

    // useMediaQuery

    const theme = useTheme();
    const denseProgressHeader = useMediaQuery(theme.breakpoints.down('xl'));

    const [searchParams, setSearchParams] = useSearchParams({ page: 0, pageSize: 25 });

    const page = parseInt(searchParams.get('page')) || 0;
    const pageSize = parseInt(searchParams.get('pageSize')) || 25;

    const sortField = searchParams.get('sortField') || '';
    const sortOrder = searchParams.get('sortOrder') || 0;

    useEffect(() => {

        setSearchParams(prev => {
            prev.set('page', 0);
            return prev;
        }, { replace: true });

    }, [searchFilter, sortField, sortOrder]);



    const [filterToggle, setFilterToggle] = useState(true);

    useEffect(() => {

        if (viewportWidth < 1560 && !filterToggle) { // toggle off when window gets smaller than 1560px
            setFilterToggle(true);
        }

    }, [viewportWidth])

    //const [patientList, setpatientList] = React.useState([]);
    //const [patientDashboard, setPatientDashboard] = React.useState([]);

    const patientsQuery = usePatientsQuery({
        page: page,
        pageSize: pageSize,
        sortField: sortField,
        sortOrder: sortOrder,
        searchFilter: searchFilter,
    })

    useEffect(() => {
        if (patientsQuery.isSuccess) {
            // setpatientList(patientsQuery.data.data.patientList);
            //setPatientDashboard(patientsQuery.data.data.dashboard);
            if (formOpen && selectedRow && patientsQuery.data.data.patientList.find(patient => patient.caseNumber === selectedRow?.caseNumber)) {
                setselectedRow(patientsQuery.data.data.patientList.find(patient => patient.caseNumber === selectedRow?.caseNumber))
            }


        }
        if (patientsQuery.isError && patientsQuery?.error?.response?.status !== 401) {

            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Fetching Patient Data`,
                    severity: 'error'
                }
            )

        }
    }, [patientsQuery.data, patientsQuery.isSuccess, patientsQuery.isError])

    const [uploadProgress, setUploadProgress] = useState(0);

    const uploadShortcutMutation = useUploadShortcutMutation({ setsnackbarStatus, setloadingPatientsList, setUploadProgress })

    const onRowSelect = async (event) => {
        setSelectedTab("PatientCaseInformation");

        setselectedRow(event.data);

        setformOpen(true);
        setTimeout(() => {
            setmodalPosition('0%')
        }, 15);

        setFormCreateMode(false);


    };


    const onRowUnselect = (event) => { setselectedRow(null) };

    const handleformOpen = () => {
        setformOpen(true);

        setTimeout(() => {
            setmodalPosition('0%')
        }, 15);

        setSelectedTab("PatientCaseInformation");
        setFormCreateMode(true);

    }


    const documentProgressBodyTemplate = (data) => {
        const hospCaseAmount = data.hospCases?.length;
        return (<>
            {data.hospCases.map((hospCase, index) => {
                /* Old progress with proforma creator
                                const docProgress = [
                                    hospCase?.files?.some(file => file?.filetype === "patientDoc") ?
                                        hospCase?.flags?.MissingDocs ? 2 : 1 : 0,

                                    hospCase?.Proforma_Date ? hospCase?.flags?.RejectedProformabyOp ? 2 : 1 : 0,

                                    hospCase?.GOP_Date ? hospCase?.flags?.RejectedGOPbyUser ? 2 : 1 : 0,

                                    hospCase?.files?.some(file => file?.filetype === "invoice") ?
                                        hospCase?.flags?.RejectedInvoicebyOp ? 2 : 1
                                        :
                                        hospCase?.GOP_Date ? 0 : -1, // -1 = no invoice, no patient doc, then download button
                                ];
                */
                const docProgress = [
                    hospCase?.files?.some(file => file?.filetype === "patientDoc") ?
                        hospCase?.flags?.MissingDocs ? 2 : 1 : 0,

                    hospCase?.GOP_Date ? hospCase?.flags?.RejectedGOPbyUser ? 2 : 1 : 0,

                    hospCase?.files?.some(file => file?.filetype === "proforma") ? hospCase?.flags?.RejectedProformabyOp ? 2 : 1 : 0,

                    hospCase?.files?.some(file => file?.filetype === "invoice") ?
                        hospCase?.flags?.RejectedInvoicebyOp ? 2 : 1
                        :
                        hospCase?.GOP_Date ? 0 : -1, // -1 = no invoice, no patient doc, then upload button
                ];

                return <CompletionBar
                    key={`${data?.RBKCaseNo}-${index}`}
                    uploadShortcutMutation={uploadShortcutMutation}
                    docProgress={docProgress}
                    RBKCaseNo={data.RBKCaseNo}
                    index={index}
                    hospCaseAmount={hospCaseAmount}
                    barUserID={hospCase.userID}
                />

            })}


        </>);
    };


    return (
        <>

            {
                patientsQuery.isFetching || queryClient.isFetching > 0 &&
                <Backdrop sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loadingPatientsList || queryClient.isFetching > 0 || patientsQuery.isFetching}  >
                    <CircularProgress size={'4rem'} />
                </Backdrop>
            }


            <DataTable
                onSort={handleSort}
                sortField={sortField}
                sortOrder={sortOrder}
                onContextMenu={(e) => contextMenuRef.current.show(e.originalEvent)}
                contextMenuSelection={rightselectedRow}
                onContextMenuSelectionChange={(e) => setrightselectedRow(e.value)}
                id='divTable'
                ref={datatableRef}
                selection={selectedRow}
                selectionMode="single"
                onRowUnselect={onRowUnselect}
                metaKeySelection={false}
                onRowSelect={onRowSelect}
                stripedRows
                scrollable
                scrollHeight="flex"
                rowClassName={rowClass({ opMode, confirmedUserID })}
                resizableColumns
                header={
                    <DataTableHeader
                        viewportWidth={viewportWidth}
                        lang={lang}
                        opMode={opMode}
                        filterToggle={filterToggle}
                        setFilterToggle={setFilterToggle}
                        searchFilter={searchFilter}
                        setSearchFilter={setSearchFilter}
                        patientsQuery={patientsQuery}
                        handleSort={handleSort}
                    />
                }
                footer={
                    <DataTableFooter
                        patientList={patientsQuery?.data?.data?.patientList}
                        opMode={opMode}
                        handleformOpen={handleformOpen}
                        lang={lang}
                        setSearchFilter={setSearchFilter}
                        searchFilter={searchFilter}
                        uploadShortcutMutation={uploadShortcutMutation}
                        uploadProgress={uploadProgress}
                        setUploadProgress={setUploadProgress}
                        setlang={setlang}
                        patientDashboard={patientsQuery?.data?.data?.dashboard}
                        setsnackbarStatus={setsnackbarStatus}
                    />
                }
                showGridlines
                size="small"
                dataKey="caseNumber"
                className="DataGrid"
                value={patientsQuery?.data?.data?.patientList}
                style={{ width: '100%' }}
                tableStyle={{ width: '100%' }}
            >
                <Column field="caseNumber" header={opMode ? `RBK ${TextLanguage["RefNo"][lang]}` : TextLanguage["RefNo"][lang]} headerStyle={{ width: '11rem', minWidth: '120px', height: '1rem' }}
                    sortable={!(searchFilter?.FlagMessage || searchFilter?.fUpload)}
                    key={'caseNumber-Col'}
                    body={formatCaseNo({ confirmedUserID, opMode })} />
                {
                    <Column field="LDMCaseNo" header={`LDM ${TextLanguage["RefNo"][lang]}`} headerStyle={{ width: '11rem', minWidth: '120px' }}
                        sortable={!(searchFilter?.FlagMessage || searchFilter?.fUpload)}
                        key={'LDMCaseNo-Col'}
                        body={formatLDMCaseNo} />}

                <Column field="name" header={`${TextLanguage["PatientName"][lang]}`} headerStyle={{ width: '8rem', minWidth: '120px' }}
                    key={'name-Col'}
                    body={formatPatientName} />
                {opMode && searchFilter?.fUpload &&
                    <Column field="latestUploadedAt" header={TextLanguage["LatestUpload"][lang]}
                        sortable={false}
                        headerStyle={{ width: '5rem', minWidth: '80px' }}
                        key={'latestUploadedAt-Col'}
                        body={formatDateUpload} />
                }
                <Column field="admissionDate" header={TextLanguage["AdmissionDate"][lang]}
                    sortable={!(searchFilter?.FlagMessage || searchFilter?.fUpload)}
                    headerStyle={{ width: '5rem', minWidth: '80px' }}
                    key={'admissionDate-Col'}
                    body={formatDateAdm} />



                {opMode && hospitalUserListData?.status === 200 &&
                    <Column field="userID" header={TextLanguage["HospitalClinic"][lang]} headerStyle={{ width: '11rem', minWidth: '120px' }}
                        key={'userID-Col'}
                        body={formatHospitalUserID(hospitalUserListData?.data)} />}
                {opMode && InsuranceCompanyListData?.status === 200 &&
                    <Column field="InsuranceCo" header={TextLanguage["InsuranceCompany"][lang]} headerStyle={{ width: '11rem', minWidth: '120px' }}
                        key={'InsuranceCo-Col'}
                        body={formatInsuranceCompany(InsuranceCompanyListData?.data)} />}
                <Column field="documentProgress" header={progressHeader({ lang, searchFilter, dense: denseProgressHeader })} headerStyle={{ width: '22rem', minWidth: '220px' }}
                    key={'documentProgress-Col'}
                    body={documentProgressBodyTemplate} />
            </DataTable>

        </>);
}





