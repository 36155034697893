
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../config.js";


export const useHospitalUserListQuery = () => {



    async function fetchHospitalUserList() {

        return await axios.get(
            `${config.API_URL}/operator/hospitalUserList`,  // Pass the filtersData directly as the second argument
            {
                withCredentials: true
            }
        );

    }

    return useQuery(
        {
            queryKey: ['hospitalUserList'],
            queryFn: fetchHospitalUserList,
            staleTime: Infinity,


        }




    )



}