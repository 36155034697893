export function normalizeString(str) {

    const turkishMap = {
        'ç': 'c', 'Ç': 'C',
        'ğ': 'g', 'Ğ': 'G',
        'ı': 'i', 'I': 'I',
        'İ': 'I', 'ö': 'o',
        'Ö': 'O', 'ş': 's',
        'Ş': 'S', 'ü': 'u',
        'Ü': 'U'
      };

      return str.replace(/[çÇğĞıİöÖşŞüÜ]/g, match => turkishMap[match]);




}