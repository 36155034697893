
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import config from '../config';

export const useUploadExcelMutation = ({setsnackbarStatus, setUploadProgress}) => {

    return useMutation({
        mutationFn: ({ formData }) => {

            return axios.post(
                `${config.API_URL}/table/parseInsuranceList`,
                formData,
                {
                    withCredentials: true,
                    onUploadProgress: progressEvent => setUploadProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                }

            );
        },
        onSettled: (data, error, variables, context) => {
           // console.log('data:',data, 'error:', error, 'variables:', variables, 'context:', context);
            if (error){
                setsnackbarStatus(
                    {
                        open: true,
                        message: `Error Uploading File`,
                        severity: 'error'

                    }
                )

            } else if (data.status === 206){
            setsnackbarStatus(
                {
                    open: true,
                    message: `Some Entries are Missing`,
                    severity: 'warning'

                }
            )
        } else if ( data.status === 200) {
            setsnackbarStatus(
                {
                    open: true,
                    message: `File Uploaded Successfully`,
                    severity: 'success'

                }
            )
        }
        },


    })






}