import { Typography } from '@mui/material';
import React from 'react';
import TextLanguage from '../../../data/TextLanguage.json';
import { useQueryClient } from '@tanstack/react-query';
import numeral from 'numeral';

const DividerElement = () => (<svg height="35" width="6">
    <line x1="0" y1="0" x2="0" y2="50" stroke="#78787887" strokeWidth="6" />
</svg>)

const typographyStyle = {

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}

export const PaymentListDataDisplay = ({
    lang, opMode,
    totalCount,
    USDValue,
    TLValue,






}) => {


    const queryClient = useQueryClient();

    const exchangeRateData = queryClient.getQueryData(['exchangeRate']);
    const exchangeRate = exchangeRateData?.data?.BanknoteSellingValue;

    const avgExrRate = parseFloat((TLValue / USDValue).toFixed(2));

    const parsedUSDPrice = numeral(USDValue).format('0.00a').toUpperCase();
    const parsedPriceTL = numeral(TLValue).format('0.00a').toUpperCase();

    const parsedAvgUSDPrice = numeral(USDValue / totalCount).format('0.00a').toUpperCase();
    const parsedAvgTLPrice = numeral(TLValue / totalCount).format('0.00a').toUpperCase()



    return (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', padding: 0 }}>

        <div style={{ padding: '0px 20px 0px 10px' }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                color: 'white',
                fontWeight: 'bolder',
                fontSize: '1.5rem',
                gap: '0.5rem'
            }}

            >
                <Typography style={{ ...typographyStyle, color: 'lightgreen', }}>
                    {`${(totalCount)} ${parseInt(totalCount) > 1 ? TextLanguage["Patients"][lang] : TextLanguage["Patient"][lang]} `}
                </Typography>
                {opMode && <>
                    <DividerElement />
                    <Typography style={{ ...typographyStyle, color: 'white' }}>
                        {`${TextLanguage["Total"][lang]}`}
                    </Typography>
                    <Typography style={{ ...typographyStyle, color: 'lightgreen', }}>
                        {parsedUSDPrice ? `$${parsedUSDPrice}` : `$0`}
                    </Typography>
                    <Typography style={{ ...typographyStyle, color: 'white', }}>
                        {`-`}
                    </Typography>
                    <Typography style={{ ...typographyStyle, color: 'lightgreen', }}>
                        {parsedPriceTL ? `${parsedPriceTL} ₺` : '0 ₺'}
                    </Typography>


                </>}
                <DividerElement />
                <Typography style={{ ...typographyStyle, color: 'white', }}>
                    {`${TextLanguage["Avg"][lang]}`}
                </Typography>
                <Typography style={{ ...typographyStyle, color: 'lightgreen', }}>
                    {`$${parsedAvgUSDPrice ? parsedAvgUSDPrice : '0'}`}
                </Typography>
                {opMode && <>
                    <Typography style={{ ...typographyStyle, color: 'white', }}>
                        {`-`}
                    </Typography>
                    <Typography style={{ ...typographyStyle, color: 'lightgreen', }}>
                        {parsedAvgTLPrice ? `${parsedAvgTLPrice} ₺` : '0 ₺'}
                    </Typography>
                </>}
            </div>
        </div>
        {opMode && <>

            <DividerElement />

            <div style={{ padding: '0px 20px' }}>
                <Typography sx={{
                    ...typographyStyle,
                    color: ((parseFloat((TLValue) / USDValue).toFixed(2) - exchangeRate < 0)) ? 'lightgreen' : 'red',
                    fontWeight: 'bolder',
                    fontSize: '0.75rem',
                }}
                >{`${TextLanguage["AvgExrRate"][lang]}: ${avgExrRate}`}</Typography>
                <Typography sx={{
                    ...typographyStyle,
                    color: 'white',
                    fontWeight: 'bolder',
                    fontSize: '0.75rem',

                }}
                >{`${TextLanguage["CurrentExrRate"][lang]}: ${parseFloat(exchangeRate).toFixed(2)}`}</Typography>

            </div>
        </>}

    </div>
    );
}
