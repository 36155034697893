
const CustomListItem = ({ label, value, index, link }) => {
    return (


        <div className='CustomListItemRow' style={{
            background: `${index % 2 === 1 ? 'var(--background-gradient-tableA)' : 'var(--background-gradient-tableB)'}`,
            height: `auto`,

        }}>
            <span
                style={{
                    letterSpacing: '0.00938em',
                    alignItems: 'flex-start', textAlign: 'flex-start', marginRight: '10px',
                    color: 'white', fontWeight: 'bold', fontSize: '15px'
                }} >

                {`${label}`}

            </span>


            {link ?
                <a href={link} download target="_self" rel="noopener noreferrer">
                    {value}
                </a>

                :
                <span
                    style={{
                        letterSpacing: '0.00938em',
                        wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'pre-line',
                        maxWidth: '300px', justifyContent: 'flex-end', textAlign: 'right',
                        marginLeft: '10px', maxWidth: '60%', fontSize: '15px'
                    }} >
                    {value}

                </span>

            }


        </div>


    );
};

export default CustomListItem;