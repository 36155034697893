import { Typography, Button, Select, MenuItem, Chip } from '@mui/material';
import React from 'react';
import LanguageSelector from '../../LanguageSelector';
import Paginator from '../Paginator';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import TextLanguage from '../../../data/TextLanguage.json';
import usePaymentListExcel from '../../../functions/usePaymentListExcel';
import { PaymentListDataDisplay } from './PaymentListDataDisplay';
import { useSearchParams } from 'react-router-dom';
//import useInsurancePaymentListExcel from '../../../functions/useInsurancePaymentListExcel';

export const DataTablePaymentFooter = ({
    selectedPaymentID, opMode,
    patientDashboard, lang, setlang,
    parsedPaymentListGroupQuery,
    parsedPaymentListHospQuery,
    parsedSelectedHospital, setparsedSelectedHospital,
}) => {


    const [searchParams, setSearchParams] = useSearchParams();

    const currentTab = parseInt(searchParams.get('currentTab')) || 0;

    const totalCount = patientDashboard?.totalPatientCount;
    const USDValue = patientDashboard?.totalGOPUSD;
    const TLValue = (patientDashboard?.totalGOPTL) / 100;

    const exportExcel = usePaymentListExcel({ lang, paymentID: currentTab === 3 ? selectedPaymentID : 'active' });

  //  const exportInsExcel = useInsurancePaymentListExcel();








    return (<div className='maindataTableFooter' style={{ justifyContent: 'space-between' }}>

        <div className='dataTableHeaderBottom'>

            {currentTab === 4 &&

                <Select
                    value={parsedSelectedHospital}
                    onChange={(event) => { setparsedSelectedHospital((event.target.value)) }}
                    sx={{ width: 400, maxHeight: 85, textAlign: 'left' }}
                >
                    {parsedPaymentListGroupQuery?.data?.data?.hospitalData?.sort((a, b) => b?.totalCount - a?.totalCount).map((option) => (
                        <MenuItem key={`selectppl-${option.hospital}`} value={option.hospital}>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
                                <Typography sx={{ textAlign: 'left' }}>
                                    {option.hospital}
                                </Typography>
                                <Chip key={`${option.hospital}-chip`} variant="outlined"
                                    sx={{
                                        border: '1px solid white',
                                        color: 'white',
                                        '& .MuiChip-label': { paddingLeft: 2, paddingRight: 2 },
                                        background: 'rgb(49,80,125)',
                                        fontWeight: 900, fontSize: '14px',
                                    }}
                                    label={`${option?.totalCount} / $${option?.AvgPriceUSD}`} />

                            </div>
                        </MenuItem>
                    ))}
                </Select>
            }

            {[0, 1].includes(currentTab) && <PaymentListDataDisplay
                lang={lang}
                opMode={opMode}
                USDValue={USDValue}
                TLValue={TLValue}
                totalCount={totalCount}
            />
            }
            {
                [2, 3].includes(currentTab) && opMode && <Button sx={{
                    marginLeft: '10px', marginRight: '10px', padding: '10px 15px', background: 'rgb(0, 107, 0)', maxHeight: '45px', width: 'auto',
                    '&:hover': {
                        outline: '2px solid green',
                        boxShadow: '0px 0px 8px 2px rgba(14, 225, 14, 0.65)',
                        background: 'rgb(0, 107, 0) !important',
                        filter: 'brightness(1.25) saturate(1.25)',
                    },
                }} onClick={exportExcel}>
                    <FileCopyIcon style={{ marginRight: "6px" }} />{TextLanguage["ExportToExcel"][lang]}
                </Button>
            }




        </div>



        <div style={{ display: 'flex', flexDirection: 'row' }}>



            <LanguageSelector lang={lang} setlang={setlang} />

            {opMode &&
                <Paginator
                    component="div"
                    sx={{ display: 'flex' }}
                    totalPatientCount={parseInt(patientDashboard?.totalPatientCount) || 0}
                    rowsPerPageOptions={[25, 50, 100]} />
            }
        </div>
    </div>);
}


/*

{
               [2, 3].includes(currentTab) && opMode && <Button sx={{
                    marginLeft: '10px', marginRight: '10px', padding: '10px 15px', background: 'rgb(213, 67, 4)', maxHeight: '45px', width: 'auto',
                    '&:hover': {
                        outline: '2px solid green',
                        boxShadow: '0px 0px 8px 2px rgba(225, 60, 14, 0.65)',
                        background: 'rgb(90, 42, 3) !important',
                        filter: 'brightness(1.25) saturate(1.25)',
                    },
                }} onClick={exportInsExcel}>
                    <FileCopyIcon style={{ marginRight: "6px" }} />{'Temp Excel Export'}
                </Button>
            }

*/