
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config.js";

export const usePatientActivityLogQuery = ({ RBKCaseNo = null }) => {


    async function PatientLogFetchFn({ queryKey }) {
        const [_key, QuerySelection] = queryKey;
        const { RBKCaseNo } = QuerySelection;
        const queryString = new URLSearchParams();
        if (RBKCaseNo) {
            queryString.set('RBKCaseNo', RBKCaseNo);
        }



        return await axios.get(`${config.API_URL}/data/activityLog?${queryString.toString()}`, {
            withCredentials: true
        });




    }

    return useQuery({
        queryKey: ['activityLog',
            {
                RBKCaseNo: RBKCaseNo ? RBKCaseNo : null

            }

        ],
        queryFn: PatientLogFetchFn,


    });




}