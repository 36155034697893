import React from "react";
import { Typography } from '@mui/material';

export const formatHospitalUserIDPayments = (HospitalUserID) => (hospCaseRow) => {


    return (
        <div key={`hospCasePay-${hospCaseRow._id}`} style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ color: 'white', fontWeight: 'bold', fontSize: '1rem' }}>
                {HospitalUserID?.find(hosp => hosp.value === hospCaseRow._id)?.label}
            </Typography>
        </div>
    )
}