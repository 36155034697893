import React, { useState, useEffect } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputMask from 'react-input-mask';
import TextLanguage from '../../../../../data/TextLanguage.json';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import dayjs from 'dayjs';

import { useUpdatePatientMutation } from '../hooks/useUpdatePatientMutation';

import {
    TextField,
    Button,
    Typography,
    MenuItem,
    Chip
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

const PatientEditForm = ({
    selectedRow, opMode, setformEditmodeDisplay, lang, dispatch,
    patientForm, handleFieldChange, setselectedRow, currencies }) => {

        const [dateTargetAdmission, setdateTargetAdmission] = useState(dayjs());
        const [dateTargetDob, setdateTargetDob] = useState(dayjs());

        const handleDateChange = (fieldName) => (date) => {
            let formatDate = new Date(date.$d);
            //formatDate = (formatDate.toLocaleString('en-GB').split(',')[0]);
            dispatch({
                type: 'SET_FIELD',
                fieldName: fieldName,
                payload: formatDate,
            });
        };

        useEffect(() => {

            const admissionDate = new Date(selectedRow?.admissionDate);
            const dob = new Date(selectedRow?.dob);
            setdateTargetAdmission(dayjs(admissionDate));
            setdateTargetDob(dayjs(dob));

            dispatch({
                type: 'REPLACE',
                tempval: {
                    ...selectedRow,
                    dob: dob,
                    admissionDate: admissionDate,
                },
            });


        }, [])



        useEffect(() => { handleDateChange('admissionDate')(dateTargetAdmission) }, [dateTargetAdmission])
        useEffect(() => { handleDateChange('dob')(dateTargetDob) }, [dateTargetDob])



    const queryClient = useQueryClient();
    const InsuranceCompanyListData = queryClient.getQueryData(['insCoList']);


    const updatePatientMutation = useUpdatePatientMutation({ setformEditmodeDisplay, setselectedRow });

    const handleSubmitEdit = async (e) => {
        e.preventDefault();
        const appendedData = { updateType: 'patientInfo' };
        updatePatientMutation.mutate({ patientForm: patientForm, appendedData: appendedData })
    };


    return (<div className='PatientCaseInfoEditForm'>


        <div className="HeaderUnderlined" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <span style={{ fontSize: "1.65rem", color: 'lightblue', fontWeight: 'bold' }}> {selectedRow?.RBKCaseNo}  </span>
            {opMode ?
                <div>
                    <Button sx={{ borderRadius: '8px 0px 0px 8px', borderRight: '2px solid rgba(125,188,255,0.5)' }}
                        onClick={() => { setformEditmodeDisplay(false) }}> <EditIcon></EditIcon></Button>
                    <Button sx={{ borderRadius: '0px 8px 8px 0px', background: '#8b0000bd' }}
                        onClick={() => { setformEditmodeDisplay(true) }}> <CancelIcon></CancelIcon></Button>
                </div> : null}
        </div>




        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '2rem' }}>
            <div className='formHalfSection' style={{ paddingRight: 10 }}>
                <div className='formGroup'>
                    <div className="formRow">
                        <TextField
                            label={TextLanguage["Name"][lang]}
                            value={patientForm.name}
                            onChange={handleFieldChange('name')} /></div>




                    <div className="formRow">
                        <DatePicker

                            label={TextLanguage["DateOfBirth"][lang]}
                            value={dateTargetDob}
                            onChange={(date) => setdateTargetDob(date)}
                            renderInput={(props) => <TextField {...props} />} />
                    </div>

                    <div className="formRow">
                        <DatePicker
                            label={TextLanguage["AdmissionDate"][lang]}
                            value={dateTargetAdmission}
                            onChange={(date) => setdateTargetAdmission(date)}
                            renderInput={(props) => <TextField {...props} />} />
                    </div>

                    <div className="formRow">
                        <InputMask
                            mask="+999999999999999"
                            maskChar={null}
                            value={patientForm.mobilePhone}
                            onChange={handleFieldChange('mobilePhone')}
                        >
                            {() => <TextField label={TextLanguage["Phone"][lang]} />}
                        </InputMask>
                    </div>
                    <div className="formRow">
                        <TextField
                            label={TextLanguage["Complaints"][lang]}
                            multiline
                            rows={2}
                            value={patientForm.complaints}
                            onChange={handleFieldChange('complaints')} />
                    </div>

                </div>
            </div>

            <div className='formHalfSection' style={{ paddingLeft: 15 }}>
                <div className='formGroup'>
                    <div className="formRow">
                        {(InsuranceCompanyListData?.status === 200) && <TextField
                            id="outlined-select-insCo"
                            select
                            sx={{ width: '100%', '& .MuiSelect-select': { display: 'flex', gap: '1rem', justifyContent: 'space-between' } }}
                            value={patientForm.InsuranceCo}
                            onChange={handleFieldChange('InsuranceCo')}
                            defaultValue={InsuranceCompanyListData?.data[0].value}
                            helperText={TextLanguage["SelectInsuranceCompany"][lang]}
                        >

                            <MenuItem key={'empty'} value={''} sx={{ gap: '1rem', justifyContent: 'space-between' }}>

                            </MenuItem>
                            {InsuranceCompanyListData?.data?.map((option) => (
                                <MenuItem key={option.value} value={option.value} sx={{ gap: '1rem', justifyContent: 'space-between' }}>
                                    <Typography>{option.label}</Typography>
                                    <Chip key={`${option.value}-chip`} variant="outlined"
                                        sx={{
                                            border: '1px solid white',
                                            color: 'white',
                                            '& .MuiChip-label': { paddingLeft: 2, paddingRight: 2 },
                                            background: option.type === 'RBK' ? 'rgb(157, 45, 32)' : 'rgb(49,80,125)',
                                            fontWeight: 900, fontSize: '14px',
                                        }}
                                        label={option.type} />
                                </MenuItem>
                            ))}
                        </TextField>}
                    </div>

                    <div className="formRow">
                        <InputMask
                            mask="99999999999999999999"
                            maskChar={null}
                            value={patientForm.policyNo}
                            onChange={handleFieldChange('policyNo')}
                        >
                            {() => <TextField label={TextLanguage["PolicyNo"][lang]} />}
                        </InputMask>
                    </div>

                    <div className="formRow">
                        <TextField
                            id="outlined-select-currency"
                            select
                            label={TextLanguage["Select"][lang]}
                            value={patientForm.franchise}
                            onChange={handleFieldChange('franchise')}
                            helperText={TextLanguage["SelectFranchiseAmount"][lang]}
                        >
                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className="formRow">
                        <TextField
                            label={TextLanguage["Notes"][lang]}
                            multiline
                            rows={3}
                            value={patientForm.notes}
                            onChange={handleFieldChange('notes')} />
                    </div>
                </div>
            </div>

        </div>




        <div className="formRow" style={{ justifyContent: 'center', width: '100%' }}>
            <Button
                sx={{
                    width: '100%', maxWidth: '300px', height: 60, p: 2, m: 2, border: '1px solid #223b59b8',
                    boxShadow: ' 0px 0px 12px 2px rgba(0, 0, 0, 0.33)'
                }}

                onClick={handleSubmitEdit}>
                <Typography>Submit</Typography></Button>
        </div>
    </div>);
}

export default PatientEditForm;
