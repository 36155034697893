import React, { useState } from 'react';
import {
    Button,
    Typography, Select,
    FormControl,
    MenuItem,
    InputLabel
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

export const FilterSelector = ({
    filterLabel,
    optionsList,
    selectLabel,
     setSearchFilter,
     searchFilter
}) => {

    const [filterValue, setfilterValue] = useState('');
    const handleFilterSelect = (e) => {
        setfilterValue(e.target.value);
        setSearchFilter({ ...searchFilter, [filterLabel]: e.target.value });
    }
    const handleClearButton = () => {
        setfilterValue('');
        setSearchFilter({ ...searchFilter, [filterLabel]: null });
    }



    return <FormControl sx={{ minWidth: 150 }}>
        <InputLabel shrink={true} id="Hosp-filterpay-select-label">
            <Typography>
                {selectLabel}
            </Typography>
        </InputLabel>
        <Select
            value={filterValue}
            onChange={handleFilterSelect}
            sx={{ width: 250, maxHeight: 45, textAlign: 'left' }}
        >
            {optionsList?.sort(
                (a, b) => -b?.label?.localeCompare(a?.label)
            ).map((option) => (
                <MenuItem key={`Cont-${filterLabel}-${option?.value}`} value={option?.value}>
                    {option?.label}
                </MenuItem>
            ))}
        </Select>
        <Button onClick={handleClearButton} sx={{
            '&:hover': {
                background: 'transparent',
                boxShadow: 'none',
                outline: 'none',
                border: 'none',
            },
            background: 'transparent !important',
            position: 'absolute !important',
            height: '50px !important',
            minWidth: '50px !important',
            width: '50px !important',
            right: "0px",
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 100,
        }}> <CancelIcon />
        </Button>
    </FormControl>;
}
