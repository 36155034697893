import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/tr';

import {
    Typography,
    Modal,
    IconButton,
    Drawer
} from '@mui/material';
import { ErrorBoundary } from "react-error-boundary";

import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from 'react';

import PatientFormContainer from '../components/PatientFormContainer/PatientFormContainer';

import { useExchangeRateQuery } from '../hooks/useExchangeRateQuery';
import { useValidateQuery } from '../hooks/useValidateQuery';


import TextLanguage from '../data/TextLanguage.json';

import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import { ContextMenu } from 'primereact/contextmenu';

import { FileShareTable } from '../components/DataTable/FileShareTable';
import RemoveConfirmationDialog from '../components/RemoveConfirmationDialog';

import useLocalStorage from '../hooks/useLocalStorage';
import { CustomSnackbar } from '../components/CustomSnackbar';
import { useQueryClient } from '@tanstack/react-query';
import { useHospitalUserListQuery } from '../hooks/useHospitalUserListQuery';
import { useInsuranceCompanyListQuery } from '../hooks/useInsuranceCompanyListQuery';

import { useSocket } from '../components/contextProviders/SocketProvider';
import RBKIcon from '../resources/icon.png';
import NavigationBar from '../components/NavigationBar';
import { ActivityLog } from '../components/PatientFormContainer/pages/PatientInfoPage/components/ActivityLog';
import { useRefreshQuery } from '../hooks/useRefreshQuery';



//import "primereact/resources/themes/lara-light-indigo/theme.css";

//const socket = io("https://localhost");


function FilesharePage() {

    const currentPage = 'Fileshare';
    const theme = useTheme();



    const socket = useSocket();

    const [lang, setlang] = useLocalStorage('lang', 'enUS');

    const [opMode, setopMode] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams({ page: 0 , pageSize: 25});

    const [onRowFileURLs, setonRowFileURLs] = useState(null);
    const [formOpen, setformOpen] = useState(false);
    const [formCreateMode, setFormCreateMode] = useState(false);


    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        setDrawerOpen(open);
      };


    const [selectedTab, setSelectedTab] = useState("PatientCaseInformation");
    const [selectedhospCaseID, setselectedhospCaseID] = useState(null);
    const [selectedRow, setselectedRow] = useState(null);
    const [modalPosition, setmodalPosition] = useState('-100%');


    const [snackbarStatus, setsnackbarStatus] = useState({
        open: false,
        message: '',
        severity: 'success'
    });


    const hospitalUserListQuery = useHospitalUserListQuery();
    const insCoListQuery = useInsuranceCompanyListQuery({ opMode });

    const invalidateDebounceRef = useRef(null);

    const [notificationState, setNotificationState] = useState(true);

    useEffect(() => {

        if (notificationState && Notification.permission !== "granted") {
            Notification.requestPermission();
        }


    }, [notificationState])


    useEffect(() => {

        socket.on('update', (msg) => {




            invalidateDebounceRef.current && clearTimeout(invalidateDebounceRef.current);
            invalidateDebounceRef.current = setTimeout(() => {
                queryClient.invalidateQueries({ queryKey: ['patients'] });
                queryClient.invalidateQueries({ queryKey: ['activityLog'] });
            }, 250);

            if (msg.type === 'CommentsUpdated') {
                let commentBy = '';
                if (msg?.value?.by !== "Operator") {
                    commentBy = hospitalUserListQuery?.data?.data.find(hosp => msg.value.by === hosp.value)?.label ?
                        `${hospitalUserListQuery?.data?.data.find(hosp => msg.value.by === hosp.value)?.label}:` : '';
                } else {
                    commentBy = 'RBK:';
                }
                if ('Notification' in window && notificationState) {

                    Notification.requestPermission().then(function (permission) {

                        if (permission === 'granted') {

                            var notification = new Notification(`${msg.value.RBKCaseNo}`, {
                                body: `${commentBy} ${msg.value.comment} `,
                                icon: RBKIcon,
                                silent: true
                            });


                            notification.onclick = function () {
                                // Create event to focus open relevant Case + Tab
                                // Open Modal with given info
                                const { missingDocs, missingProforma, missingGOP, missingInvoice, FlagError, ...updatedSearchFilter } = searchFilter;
                                setSearchFilter({ ...updatedSearchFilter, FlagMessage: true });
                                handleSort({ sortOrder: 0, sortField: null });

                                setSelectedTab("PatientCaseInformation");
                                setselectedRow(msg?.data);
                                !(msg?.value?.by === 'Operator') && setselectedhospCaseID(msg?.value?.by);
                                setformOpen(true);
                                setTimeout(() => {
                                    setmodalPosition('0%')
                                }, 15);
                                setFormCreateMode(false);

                                window.focus();
                            };

                        } else {
                            console.log('Notification permission denied');
                        }
                    });
                } else {

                    console.log('Browser does not support notifications');
                }
            } else if(msg.type === 'NewMessage') {

                if ('Notification' in window && notificationState) {

                    Notification.requestPermission().then(function (permission) {

                        if (permission === 'granted') {

                            var notification = new Notification(`+${msg?.value?.from}`, {
                                body: `${msg?.value?.content}`,
                                icon: RBKIcon,
                                silent: true
                            });

                            notification.onclick = function () {
                                navigate('/chat');
                                window.focus();
                            };

                        } else {
                            console.log('Notification permission denied');
                        }
                    });
                } else {
                    console.log('Browser does not support notifications');
                }
            }


        }).on('mail', (msg) => {
            queryClient.invalidateQueries({ queryKey: ['mailFetch' , { RBKCaseNo: msg?.RBKCaseNo, userID: msg?.userID  }] });

        })


        return () => {
            socket.off('update');
        }
    }, [socket])


    const [loadingPatientsList, setloadingPatientsList] = useState(true);

    // Context Menu need to fix InsuranceStart and InsuranceEnd, add diagnosis and caseType for the processing stage
    const contextMenuRef = useRef(null);
    const [rightselectedRow, setrightselectedRow] = useState(null);
    const [rightselectedRowCaseNo, setrightselectedRowCaseNo] = useState(null);

    const menuModel = opMode ? [
        { label: 'Copy', command: () => copyToClipboard(rightselectedRow) },

        { separator: true },

        {
            label:
                <Typography variant='button' sx={{ color: 'red', fontSize: '1rem', fontWeight: 'bolder', p: 0, m: 0 }}>
                    {TextLanguage["Remove"][lang]}
                </Typography>
            ,
            command: () => removeCase(rightselectedRow)
        },

    ]
        :
        [
            { label: 'Copy', command: () => copyToClipboard(rightselectedRow) }
        ]
        ;




    const copyToClipboard = (rowData) => {
        const clipboardData = opMode ? `${rowData?.admissionDate}\t${rowData?.RBKCaseNo}\t${rowData?.name}\t${rowData?.dob}\t${rowData?.franchise}\t${rowData?.policyNo}\t${rowData?.InsurancePeriodStart}\t${rowData?.InsurancePeriodEnd}\t${rowData?.hotel}\t${rowData?.diagnosis}\t${rowData?.caseType}\t${rowData?.insuranceBill}\t${rowData?.GOPPriceUSD}\t${(rowData?.insuranceBill - rowData?.GOPPriceUSD)}\t${rowData?.InsuranceCo}\t${hospitalUserListQuery?.data?.data.find(hosp => rowData?.userID === hosp.value)?.label}`
            :
            `${rowData?.admissionDate}\t${rowData?.RBKCaseNo}\t${rowData?.name}\t${rowData?.dob}\t${rowData?.franchise}\t${rowData?.policyNo}\t${rowData?.InsurancePeriodStart}\t${rowData?.InsurancePeriodEnd}\t${rowData?.hotel}\t${rowData?.diagnosis}\t${rowData?.caseType}\t${rowData?.insuranceBill}\t${rowData?.GOPPriceUSD}\t${(rowData?.insuranceBill - rowData?.GOPPriceUSD)}\t${rowData?.InsuranceCo}\t`
        navigator.clipboard.writeText(clipboardData);
    }

    const removeCase = async (selection) => {
        setrightselectedRowCaseNo(selection?.RBKCaseNo);
        setConfirmFormOpen(true);
    }

    // Context Menu End

    const navigate = useNavigate();
    const [searchFilter, setSearchFilter] = useState({});

    const [confirmedUserID, setconfirmedUserID] = useState(null);

    const validateQuery = useValidateQuery();
    const refreshQuery = useRefreshQuery();

    useEffect(() => {

        if (refreshQuery.isError) {
            console.log('Refresh Token Expired');
            navigate('/');
        } else if (refreshQuery.isSuccess) {
          queryClient.refetchQueries({ type: 'active', stale: true });
        }

    }, [refreshQuery.isSuccess, refreshQuery.isError])


    useEffect(() => {
        if (validateQuery.isSuccess) {
            setconfirmedUserID(validateQuery.data.data?.userID);
            setloadingPatientsList(false);
        }

    }, [validateQuery.data, validateQuery.isSuccess, validateQuery.isError])

    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

    const queryClient = useQueryClient();

    useEffect(() => {

        queryClient.invalidateQueries({ queryKey: ['patients'] });
        queryClient.invalidateQueries({ queryKey: ['validate'] });
        queryClient.invalidateQueries({ queryKey: ['activityLog'] });

        const handleResize = () => {
            setViewportWidth(window.innerWidth);
            setViewportHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [])

    useEffect(() => {

        if (["Operator", "Admin", "Expertise"].includes(confirmedUserID)) {
            setselectedhospCaseID(null);
            setopMode(true);
        } else {
            setopMode(false);
            setselectedhospCaseID(confirmedUserID);
        }
    }, [confirmedUserID])




    const handleSort = (event) => {
        setSearchParams(prev => {
            prev.set('sortField', event.sortField);
            prev.set('sortOrder', event.sortOrder);
            return prev;
        }, { replace: true });
    }



    const handleModalFormClose = () => {

        setformOpen(false);
        setmodalPosition('-100%')
        setFormCreateMode(false);
        setselectedRow(null);

    };





    const exchangeRateQuery = useExchangeRateQuery({opMode});

    useEffect(() => {
        if (exchangeRateQuery.isError && exchangeRateQuery?.error?.response?.status !== 401) {
            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Fetching Exchange Rate`,
                    severity: 'error'
                }
            )

        }
    }, [exchangeRateQuery.data, exchangeRateQuery.isError])

    useEffect(() => {
        if (!formOpen) { // triggered on close
            if (opMode) { setselectedhospCaseID(null) }
        }
    }, [formOpen]);

    const modalWidthSelector = (selectedTab === 'PatientCaseInformation') ? // First Page?
        (selectedhospCaseID ? // OpMode, Picked a case?
            { width: 'auto', maxWidth: '100vw'} :
            { width: 'auto', maxWidth: '100vw', })
        :
        { width: 'auto' }


    const [confirmFormOpen, setConfirmFormOpen] = useState(false);


    return (

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">

            <div className="App-main">


                <CustomSnackbar
                    snackbarStatus={snackbarStatus}
                    setsnackbarStatus={setsnackbarStatus}
                />
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>

                    <NavigationBar
                        currentPage='fileshare'
                        lang={lang}
                        opMode={opMode}
                        notificationState={notificationState}
                        setNotificationState={setNotificationState}
                        toggleDrawer={toggleDrawer}
                    />

                    <div className='dataTableContainer'>

                        <ContextMenu model={menuModel} ref={contextMenuRef} onHide={() => setrightselectedRow(null)} />


                        {confirmedUserID && <FileShareTable
                            viewportWidth={viewportWidth}
                            setlang={setlang}
                            confirmedUserID={confirmedUserID}
                            handleSort={handleSort}
                            contextMenuRef={contextMenuRef}
                            rightselectedRow={rightselectedRow}
                            setrightselectedRow={setrightselectedRow}
                            selectedRow={selectedRow}
                            setselectedRow={setselectedRow}
                            opMode={opMode}
                            lang={lang}
                            setformOpen={setformOpen}
                            formOpen={formOpen}
                            setFormCreateMode={setFormCreateMode}
                            setmodalPosition={setmodalPosition}
                            searchFilter={searchFilter}
                            setSearchFilter={setSearchFilter}
                            setSelectedTab={setSelectedTab}
                            loadingPatientsList={loadingPatientsList}
                            setloadingPatientsList={setloadingPatientsList}
                            setsnackbarStatus={setsnackbarStatus}
                        />}



                    </div>
                </div>



                <Modal
                    open={formOpen}
                    onClose={handleModalFormClose}
                >
                    <div className="modal-container"
                        style={{
                            ...modalWidthSelector,transform: 'translateY(-50%)',
                            left: `${modalPosition}`, transition: '0.5s ease-in-out 0s !important',
                            height: '100vh', overflowY: 'visible', maxWidth:'100vw'
                        }}>
                        <IconButton
                            aria-label="close"
                            onClick={handleModalFormClose}
                            fontSize="large"
                            sx={{
                                position: 'absolute', right: 42, top: 32,
                                color: 'red',
                                zIndex: 1000,
                                borderRadius: '8px',
                                '&:hover': { background: 'transparent' }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>

                        {formOpen &&
                            <PatientFormContainer
                                setsnackbarStatus={setsnackbarStatus}
                                lang={lang}
                                selectedhospCaseID={selectedhospCaseID}
                                setselectedhospCaseID={setselectedhospCaseID}
                                confirmedUserID={confirmedUserID}
                                opMode={opMode}
                                onRowFileURLs={onRowFileURLs}
                                setonRowFileURLs={setonRowFileURLs}
                                selectedRow={selectedRow}
                                setselectedRow={setselectedRow}
                                formCreateMode={formCreateMode}
                                selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab}
                                setformOpen={setformOpen}
                            />
                        }



                    </div>
                </Modal>

                <RemoveConfirmationDialog
                    lang={lang}
                    confirmFormOpen={confirmFormOpen}
                    setConfirmFormOpen={setConfirmFormOpen}
                    rightselectedRowCaseNo={rightselectedRowCaseNo}
                    setloadingPatientsList={setloadingPatientsList}
                    setsnackbarStatus={setsnackbarStatus}
                />

                        <Drawer
                        anchor='left'
                        open={drawerOpen}
                        onClose={toggleDrawer(false)}

                        >
                            <div style={{   minWidth: '340px', width: '25vw',maxWidth: '500px', height:'100%' }}>

                                <ActivityLog

                                setformOpen={setformOpen}
                                setselectedRow={setselectedRow}
                                setSelectedTab={setSelectedTab}
                                setmodalPosition={setmodalPosition}
                                setFormCreateMode={setFormCreateMode}
                                mainActivityLog={true}
                                lang={lang}
                                />
                            </div>
                        </Drawer>


            </div>

        </LocalizationProvider>










    );
}

export default FilesharePage;






