import React, { useState } from 'react';

import { Button, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const TextFilter = ({ onFilter, labelName, props, dense, placeholderTemplate = '' }) => {
    const [selectedFilterVal, setselectedFilterVal] = useState('');

    const handleFieldChange = (e) => {
        setselectedFilterVal(e.target.value);
        console.log(e.target.value)
        onFilter(e.target.value);
    };

    return (
        <div className='CustomFilterContainer' >
            <TextField
            InputLabelProps={{
                shrink: true,
              }}
                label={labelName}
                value={selectedFilterVal}
                onChange={handleFieldChange}
                placeholder={placeholderTemplate}
                sx={ {width: '200px', '& .MuiInputBase-input': {
                    padding: (dense === true)?  '0.8rem 1.25rem 0.5rem 1.25rem': '1rem',

                }


            } }
            />

            <Button onClick={() => {
                setselectedFilterVal("");
                onFilter(null);
            }

            } sx={{

                background: 'transparent !important',
                position: 'absolute !important',
                height: '50px !important',
                minWidth: '50px !important',
                width: '50px !important',
                left: "150px",
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 100,
                '&:hover': {
                    background: 'transparent !important',
                    boxShadow: 'none !important',
                    border: 'none',
                    outline: 'none',

                }


            }}> <CancelIcon />
            </Button>
        </div>
    );
};

export default TextFilter;
