import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import config from '../../config';
import { useQueryClient } from '@tanstack/react-query';


const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

    const queryClient = useQueryClient();

  useEffect(() => {


    // Create the socket instance only when the token is available

      const newSocket = io(config.API_URL, {

        withCredentials: true
      });

      newSocket.on("connect_error", async (error) => {
        console.log(error);
        !queryClient.isFetching({ queryKey: ['refresh'] }) && await queryClient.invalidateQueries(['refresh'])
        await queryClient.refetchQueries({ type: 'active', stale: true });;
      });
      newSocket.on('disconnect', (reason) => {
        console.log('Socket disconnected:', reason);
        if (reason === 'io server disconnect') {
          // The disconnection was initiated by the server, you need to manually reconnect
          newSocket.connect();
        }
      });
      newSocket.on('reconnect', (attemptNumber) => {
        //console.log('Socket reconnected after', attemptNumber, 'attempts');
      });


      setSocket(newSocket);
/*
      window.addEventListener('beforeunload', (event) => {
        event.preventDefault();

        newSocket.close();
      });

      return () => {
        window.removeEventListener('beforeunload', (event) => {
          event.preventDefault();
          newSocket.close();
        });
      };
*/
  }, []);

  // Render the SocketContext.Provider only when the socket is available
  return (
    socket && (
      <SocketContext.Provider value={socket}>
        {children}
      </SocketContext.Provider>
    )
  );
};
